@import '../variables';

.TransferSubscriptionPage_default-modal .QboModal__wrapper {
  width: 80%;
}

.TransferSubscriptionPage__wrapper {
  display: flex;
  min-height: 60vh;
  padding: 1.5rem 2rem 2rem;
  gap: 1.5rem;

  .TransferSubscriptionPage__information-box {
    justify-content: space-between;
    min-height: 15vh;
  }

  .TransferSubscriptionPage__center-item {
    min-height: 20vh;

    .QboCard__title-card {
      align-self: center;
    }
  }

  .TransferSubscriptionPage__source-target {
    justify-content: space-between;
    gap: 2rem;
  }

  .TransferSubscriptionPage__source-target-card-wrapper {
    display: flex;
    flex: 1;
  }

  .TransferSubscriptionPage__footer-button {
    justify-content: space-between;
  }

  .TransferSubscriptionPage__text-with-label {
    max-width: 80%;
    word-wrap: break-word;
  }
}

.TransferSubscriptionFormPage__wrapper {
  display: flex;
  padding: 1.5rem 2rem 2rem;
  gap: 1.5rem;

  .TransferSubscriptionFormPage__form-group {
    // max-width: 50%;
  }

  button {
    width: 20rem;
    align-self: flex-end;
  }
}
