.Box__main {
  flex-grow: 1;
  position: relative;
  // width: 100%;
  max-width: calc(100% - 55px);
}

.Box__main_drawer_open {
  flex-grow: 1;
  position: relative;
  max-width: calc(100% - 270px);
}

.ItBox__wrapper {
  display: flex;
  flex: 1;
}

.table_paginations {
  color: 'red';
}

.ItBox__direction-row {
  flex-direction: row;
}

.ItBox__direction-column {
  flex-direction: column;
}

.ITBox_wrapper_modal{
  max-height: 60rem !important;
  overflow: auto;
}
