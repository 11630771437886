@import '../variables';

.QboButton__default {
  font-family: 'Inter' !important;
  box-shadow: none !important;

  .MuiButton-iconSizeMedium svg {
    font-size: 17px;
  }

  &.MuiButton-containedSuccess {
    background-color: $bg-success;

    &.Mui-disabled {
      background-color: $bg-success-disabled;
      color: $white-color;
    }
  }

  &.Mui-disabled {
    cursor: not-allowed !important;
  }
}

.css-ahj2mt-MuiTypography-root{
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  color: #42526e !important;
  font-family: 'Inter';
}

// QboButton
// icon position
.QboButton__default {
  .MuiButton-endIcon {
    margin-right: -4px;
    margin-left: 5px;
  }

  .MuiButton-startIcon {
    margin-right: 5px;
    margin-left: -4px;
  }

  .QboButton__dropdown-icon {
    margin-right: -4px;
    margin-left: 5px;
    font-size: 1.6rem;
    margin-top: 0.3rem;
  }
}

.QboButton__unset {
  all: unset;
  cursor: pointer;
}

.QboConnectButton__wrapper {
  @extend .QboButton__unset;

  display: flex;

  img.QboConnectButton__default {
    display: block;
  }

  img.QboConnectButton__active {
    display: none;
  }

  img.QboConnectButton__disabled {
    display: none;
  }

  &:hover {
    img.QboConnectButton__default {
      display: none;
    }

    img.QboConnectButton__active {
      display: block;
    }

    img.QboConnectButton__disabled {
      display: none;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    img.QboConnectButton__default {
      display: none;
    }

    img.QboConnectButton__active {
      display: none;
    }

    img.QboConnectButton__disabled {
      display: block;
    }
  }
}

// override typography styling
// MuiButton-outlinedPrimary
.QboButton__default .MuiTypography-root {
  font-weight: $font-weight-semi-bold !important;
}

// override styling
// MuiButton-outlinedPrimary
.QboButton__default {
  &.MuiButton-outlinedPrimary,
  &.MuiButton-outlinedPrimary:active,
  &.MuiButton-outlinedPrimary:focus {
    border: 1px solid $bg-blue-color;
    color: $bg-blue-color;
    background-color: transparent !important;
  }

  &.MuiButton-outlinedPrimary:hover {
    box-shadow: 0px 0px 0px 1px $bg-blue-color-2 !important;
    border: 1px solid $bg-blue-color-2;
  }

  &.MuiButton-outlinedPrimary:disabled,
  &.MuiButton-outlinedPrimary.disabled {
    border: $thin-border;
    border-color: $bg-blue-color;
    color: $bg-blue-color;
    opacity: 0.5;
    box-shadow: none !important;
  }
}

// override styling
// MuiButton-outlinedSecondary
.QboButton__default {
  &.MuiButton-outlinedSecondary,
  &.MuiButton-outlinedSecondary:active,
  &.MuiButton-outlinedSecondary:focus {
    border: 0.01rem solid $bg-gray-color-5;
    color: $black-color;
    background-color: transparent !important;
  }

  &.MuiButton-outlinedSecondary:hover {
    box-shadow: 0rem 0rem 0rem 0.1rem $bg-gray-color-5 !important;
    border: 0.1rem solid $bg-gray-color-5;
  }

  &.MuiButton-outlinedSecondary:disabled,
  &.MuiButton-outlinedSecondary.disabled {
    opacity: 0.5;
    box-shadow: none !important;
  }
}

// override styling
// MuiButton-outlinedPrimary
.QboButton__default {
  &.MuiButton-outlinedInherit,
  &.MuiButton-outlinedInherit:active,
  &.MuiButton-outlinedInherit:focus {
    border: 1px solid $bg-gray-color-5;
    color: $black-color;
    background-color: transparent !important;
  }

  &.MuiButton-outlinedInherit:hover {
    box-shadow: 0px 0px 0px 1px $bg-gray-color-5 !important;
    border: 1px solid $bg-gray-color-5;
  }

  &.MuiButton-outlinedInherit:disabled,
  &.MuiButton-outlinedInherit.disabled {
    opacity: 0.5;
    box-shadow: none !important;
    cursor: no-drop;
  }
}

// override styling
// MuiButton-containedInfo
.QboButton__default {
  &.MuiButton-containedInfo,
  &.MuiButton-containedInfo:active,
  &.MuiButton-containedInfo:focus {
    border: 0.01rem solid $bg-gray-color-5;
    background-color: $gray-color;
  }

  &.MuiButton-containedInfo:hover {
    background-color: $gray-color;
    box-shadow: 0rem 0rem 0rem 0.1rem $gray-color !important;
    border: 0.1rem solid $bg-gray-color-5;
  }

  &.MuiButton-containedInfo:disabled,
  &.MuiButton-containedInfo.disabled {
    background-color: $gray-color;
    opacity: 0.5;
    box-shadow: none !important;
  }
}

// override styling
// MuiButton-text
.QboButton__default {
  &.MuiButton-text,
  &.MuiButton-text:active,
  &.MuiButton-text:focus {
    border: 0px;
    color: $bg-blue-color;
    background-color: transparent !important;
  }

  &.MuiButton-text:hover {
    background-color: $bg-gray-color-1 !important;
  }

  &.MuiButton-text:disabled,
  &.MuiButton-text.disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

// override styling
// MuiButton-containedPrimary
.QboButton__default {
  &.MuiButton-containedPrimary,
  &.MuiButton-containedPrimary:active,
  &.MuiButton-containedPrimary:focus {
    border: 0px;
    color: $bg-white-color !important;
    background-color: $bg-blue-color !important;
  }

  &.MuiButton-containedPrimary:hover {
    background-color: $bg-blue-color-2 !important;
  }

  &.MuiButton-containedPrimary:disabled,
  &.MuiButton-containedPrimary.disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

// override styling
// Small size of Button
.QboButton__default.MuiButton-sizeSmall {
  height: 3.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: $font-size-small;

  .MuiTypography-root {
    font-size: $font-size-small;
  }

  .MuiButton-iconSizeSmall svg {
    font-size: $font-size-large;
  }
}

// override styling
// Medium size of Button
.QboButton__default.MuiButton-sizeMedium {
  font-size: $font-size-default;
  height: 3.6rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .MuiTypography-root {
    font-size: $font-size-default;
  }

  .MuiButton-iconSizeMedium svg {
    font-size: $font-size-large;
  }
}

.MuiButton-sizeMedium_button {
  font-size: 12px !important;
  background-color: #b0def1 !important;
  text-transform: none !important;
  width: 100px !important;
  height: 4.3rem;
}

.css-1m7hysi-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  height: 41px;
}

.css-bf1e45-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  display: flex !important;
  align-items: center !important;
  font-size: 13px !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  background-color: #b0def1 !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
  background-color: #b0def1 !important;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  height: auto;
}

// override styling
// Large size of Button
.QboButton__default.MuiButton-sizeLarge {
  font-size: $font-size-default;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .MuiTypography-root {
    font-size: $font-size-default;
  }

  .MuiButton-iconSizeLarge svg {
    font-size: $font-size-large;
  }
}

// override styling
// variant text button
.QboButton__default.MuiButton-text {
  .MuiTypography-root {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-small;
    line-height: 2rem;
    color: $bg-blue-color;
    text-transform: none;
  }
}

.QboButton__badge.MuiBadge-root {
  .MuiBadge-badge {
    border: 0.2rem solid $bg-white-color;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    left: 0.3rem;
    top: 0.3rem;
  }

  .MuiBadge-colorPrimary {
    background-color: $bg-blue-color;
  }
}

.QboButton__default.QboButton__close-padding {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.QboButton__tooltip {
  display: flex;
}

.QboButton__loading {
  .MuiTypography-root,
  .MuiButton-iconSizeMedium svg {
    color: transparent !important;
  }

  &.MuiButton-containedPrimary svg {
    color: $white-color;
  }
}
