.UserBackupPage {
    &__information-box {
        border: 1px solid #ebebeb !important;
        background: #f3f3f3 !important;
        font-size: 10px !important;
        margin-top: 20px;
    }

    &__information-text {
        font-size: 13px !important;
        color: #767676 !important;
        white-space: pre-line !important;
    }
}