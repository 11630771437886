@import 'variables';

*,
*:after,
*:before {
  box-sizing: border-box;
}

.MuiTypography-root.MuiLink-root {
  color: $bg-blue-color;
}

html,
body,
body #root {
  font-family: $font-family !important;
  font-weight: $font-weight-default;
  line-height: $font-line-height-default;
  color: $font-color-default;
  font-size: $font-size-default;
}

html,
body,
a,
p,
body #root {
  text-decoration: none;
  letter-spacing: $font-letter-spacing-default;
}

body,
p,
body #root {
  font-size: $font-size-default;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: $font-weight-bold;
}

.MuiTypography-h5,
.MuiTypography-h4,
.MuiTypography-h3,
.MuiTypography-h2,
.MuiTypography-h1 {
  font-weight: $font-weight-bold !important;
}

@each $key, $val in $font-size-heading-list {
  .MuiTypography-root.MuiTypography-#{$key},
  .MuiTypography-root.MuiTypography-#{$key} svg {
    font-size: $val;
  }
}

.QboTypography__description {
  color: $font-color-default !important;
}

.QboLastBackupText__wrapper {
  font-size: $font-size-default;
  font-family: 'Inter';
  color: $black-color;
  font-weight: $font-weight-semi-bold;
  text-align: right;
  margin: 0px;
  line-height: 2rem;

  span {
    color: $primary-color;
    font-size: $font-size-small;
    font-weight: $font-weight-default;
  }
}

// MuiTypography overriding
.MuiTypography-root * {
  font-family: 'Inter';
}

// MuiTypography overriding
// for caption variant
.MuiTypography-root.MuiTypography-caption {
  color: $primary-color;
  font-size: $font-size-small;
}

// MuiTypography overriding
// for subtitle2 variant
.MuiTypography-root.MuiTypography-subtitle2 {
  font-size: $font-size-default;
  font-weight: $font-weight-semi-bold;
}

// MuiTypography overriding
// for primary color
.MuiTypography-root.MuiTypography-colorPrimary,
.MuiTypography-root.MuiTypography-colorPrimary svg {
  color: $primary-color;
}

// MuiTypography overriding
// for primary color
.MuiTypography-root.MuiTypography-colorSuccess,
.MuiTypography-root.MuiTypography-colorSuccess svg {
  color: $tosca-color;
}

// MuiTypography overriding
// for secondary color
.MuiTypography-root.MuiTypography-colorSecondary,
.MuiTypography-root.MuiTypography-colorSecondary svg {
  color: $black-color;
}

// MuiTypography overriding
// for danger color
.MuiTypography-root.MuiTypography-colorDanger,
.MuiTypography-root.MuiTypography-colorDanger svg {
  color: $danger-color-2;
}

// MuiTypography overriding
// for danger color
.MuiTypography-root.MuiTypography-colorMute,
.MuiTypography-root.MuiTypography-colorMute svg {
  color: $mute-color-2;
}

.QboLink__wrapper.QboLink__with-navigate {
  cursor: pointer;
}

.QboLink__wrapper.QboLink__gray-primary-hover {
  color: $mute-color-2;
  transition: $transition-default;

  &:hover {
    color: $bg-blue-color;
  }
}

mark.QboTypography__mark-label {
  background: none;
  font-weight: $font-weight-extra-bold;
}
