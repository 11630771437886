@import '../variables';

.StatusCardItemBlock__item-right-content {
  align-items: flex-end;
}

.StatusCardItemBlock__item-list {
  padding: 1.6rem 2.8rem;
  border-top: $thin-border;
  min-height: 7.5rem;

  .QboTooltip__wrapper {
    align-self: flex-start;
  }

  .MuiTypography-subtitle2 {
    color: $black-color;
  }

  &.StatusCardItemBlock__item-list-hover {
    background-color: $bg-gray-color-1;
  }
}

.StatusCardItemBlock__show-detail {
  display: flex;
  align-items: center;
  color: $blue-color;
  gap: 0.4rem;
  align-self: flex-start;

  svg {
    font-size: $font-size-icon-small;
  }
}
