@import '../variables';

// Linear Progress Styles
.QboLinearProgress__wrapper {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-end;

  .MuiLinearProgress-root {
    width: 100%;
  }

  .MuiTypography-root {
    color: $primary-color;
    font-size: $font-size-small;
  }

  .QboLinearProgress__component {
    height: 0.6rem !important;
    border-radius: 0.4rem !important;
    background-color: $bg-cyan-color !important;
    min-width: 20rem;
  }

  .QboLinearProgress__component .MuiLinearProgress-bar {
    background-color: $bg-blue-color;
  }
}

@media screen and (max-width: 745px) {
  .QboLinearProgress__wrapper .QboLinearProgress__component {
    min-width: 15rem;
  }
}

.QboSpinnerProgress__wrapper {
  &.QboSpinnerProgress__full-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    gap: 2rem;
  }

  &.QboSpinnerProgress__overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($black-color, .5);
    z-index: 1400;
  }
}
