@import '../variables';

.QboSimpleTable__wrapper {
  .QboSimpleTable__header p {
    font-weight: $font-weight-semi-bold;
  }

  .QboSimpleTable__cell-xs {
    width: 6rem;
  }

  thead {
    border-bottom: 2px solid $border-color;
  }

  table thead tr th,
  table tbody tr td {
    padding: 1.2rem;
  }

}


.view_excluded_mailbox {
  max-height: 220px;
  overflow: auto;
}
// data table override
// styling
.QboTable__wrapper table *,
.QboTable__wrapper table .MuiTableCell-root {
  font-size: $font-size-default;
  font-family: 'Inter';
}

.QboTable__wrapper table {
  .MuiTableRow-head {
    border-top: $thin-border;

    .MuiTableSortLabel-icon {
      margin-left: 1.4rem;
      font-size: 1.6rem;
    }
  }

  .MuiTableRow-head .MuiTableCell-head {
    border-bottom: 2px solid $border-color;

    &:last-child .QboTable__head-label {
      border-left: 0;
    }
  }

  .QboTable__head-label {
    border-left: $thin-border;
    display: flex;
    min-height: 3.2rem;
    margin: 1.2rem 0;
    padding: 0 1rem;
  }
  .view_excluded_mailbox_header {
    height: 1.2rem;
  }

  .view_auto_discovery_header {
    height: 1.8rem;
  }

  .QboTableCell__icon svg {
    font-size: $font-size-extra-large;
  }

  .MuiTableCell-alignRight .QboTableCell__icon {
    display: flex;
    justify-content: flex-end;
  }

  .QboTable__head-label.QboTable__head-label-no-divider {
    border-left: 0;
  }

  .QboTable__head-label span.MuiTypography-root {
    display: inline-flex;
    align-items: center;
  }

  th.MuiTableCell-root,
  th.MuiTableCell-root * {
    font-weight: $font-weight-semi-bold;
    min-width: 125px;
  }

  tbody.MuiTableBody-root th,
  tbody.MuiTableBody-root td {
    border-bottom: $thin-border;
    padding: 1.35rem;
  }

  thead.MuiTableBody-root th.QboTable__cell-with-thin-pl,
  thead.MuiTableBody-root td.QboTable__cell-with-thin-pl,
  tbody.MuiTableBody-root th.QboTable__cell-with-thin-pl,
  tbody.MuiTableBody-root td.QboTable__cell-with-thin-pl,
  .QboTable__cell-with-thin-pl .QboTable__head-label {
    padding-left: .3rem;
  }

  thead.MuiTableBody-root th.QboTable__cell-no-padding,
  thead.MuiTableBody-root td.QboTable__cell-no-padding,
  tbody.MuiTableBody-root th.QboTable__cell-no-padding,
  tbody.MuiTableBody-root td.QboTable__cell-no-padding,
  .QboTable__cell-no-padding .QboTable__head-label {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  thead.MuiTableBody-root th.QboTable__cell-size-icon:first-child,
  thead.MuiTableBody-root td.QboTable__cell-size-icon:first-child,
  tbody.MuiTableBody-root th.QboTable__cell-size-icon:first-child,
  tbody.MuiTableBody-root td.QboTable__cell-size-icon:first-child,
  .QboTable__cell-size-icon:first-child .QboTable__head-label {
    padding-left: 2.5rem;
  }

  .QboTable__cell-size-icon {
    width: 6rem;
  }

  .QboTable__cell-size-xxs {
    width: 3%;
    max-width: 3.6rem;
  }

  .QboTable__cell-size-xs {
    width: 5%;
    max-width: 4.8rem;
  }

  .QboTable__cell-size-sm {
    width: 20%;
    max-width: 20rem;
  }

  .QboTable__cell-size-md {
    width: 33%;
    max-width: 36rem;
  }

  .QboTable__cell-size-lg {
    width: 50%;
    max-width: 44rem;
  }

  .QboTable__icon {
    color: $primary-color;
    font-size: $font-size-extra-large;
    vertical-align: bottom;
  }

  tbody.MuiTableBody-root .QboTableCell__height-size-small td {
    padding: 0.95rem;
  }

  .QboTableRow__with-icon-pull-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      display: flex;
    }

    a svg {
      font-size: $font-size-extra-large;
    }
  }

  .QboTableRow__linking {
    td,
    th {
      cursor: pointer;
    }
  }

  .QboTable__cell-with-color,
  .QboTableRow__active,
  .QboTable__cell-with-color:hover,
  .QboTableRow__active:hover {
    background-color: $bg-gray-color-1;
  }

  .QboTableRow__active-link td,
  .QboTableRow__active-link th,
  .QboTableRow__active-link td svg,
  .QboTableRow__active-link th svg {
    color: $active-color;
  }

  .QboTableRow__selected {
    background-color: $bg-primary;
  }

  mark.QboTypography__mark-label {
    font-weight: $font-weight-extra-bold;
    color: $black-color;
  }
}

.QboTable__wrapper-full-border table {
  .QboTable__head-label {
    border: 0;
    height: 3rem;
    padding: 0;
  }

  thead tr th,
  tbody tr td,
  tbody tr th,
  tbody.MuiTableBody-root th,
  tbody.MuiTableBody-root td,
  tbody.MuiTableBody-root .QboTableCell__height-size-small td,
  tbody.MuiTableBody-root .QboTableCell__height-size-small th {
    border-left: $thin-border;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.QboTable__wrapper .QboTable__dropdown-checkbox {

  padding-left: 1.1rem;

  .QboTable__dropdown-button-primary svg {
    color: $blue-color;
  }

  .QboTable__dropdown-button-inherit svg {
    color: $mute-color-2;
  }

  svg {
    font-size: $font-size-large;
  }

  &.QboTable__dropdown-checkbox__small {
    svg,
    .MuiButtonBase-root {
      min-width: 10px;
    }
  }
}

.css-1v4recd-MuiTableCell-root {
  background-color: #c2e2ef !important;
  padding-top: 16px !important;
  padding-left: 17px !important;
  padding-bottom: 16px !important;
}

.css-10iu4fk {
  background-color: #c2e2ef !important;
  padding-top: 16px !important;
  padding-left: 17px !important;
  padding-bottom: 16px !important;
}

.MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.MuiAlert-standardInfo{
  margin-left: 140px !important;
  margin-top: 8px !important;
  position: absolute;
  background-color: white !important;
}
