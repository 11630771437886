.QboChipCollection {
    width: 100%;
    & > *:not(:last-child) {
        margin-bottom: .5rem;
    }
    
    &__chips {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        padding: .5rem;

        span.MuiChip-label {
            font-size: 1.4rem;
        }
    }

    &__add-icon {
        cursor: pointer;
    }
}