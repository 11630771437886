@import '../variables';

.FormControl__default-wrapper {
  .MuiInputLabel-root {
    font-size: $font-size-default;
    font-family: 'Inter';
    position: initial;
    display: block;
    bottom: 0px;
  }

  .MuiInputBase-input {
    border-radius: 0.4rem;
    position: relative;
    border: 1px solid $border-form-color;
    width: auto;
    padding: 1.2rem;
    font-family: 'Inter';
    background-color: $bg-white-color;
    font-size: $font-size-small;
    font-weight: $font-weight-default;
    color: $primary-color;
    font-family: 'Inter';
  }

  .MuiInputBase-input.Mui-disabled,
  .MuiInputBase-input:disabled {
    background-color: $bg-gray-color-4 !important;
    border: 1px solid $bg-gray-color-3 !important;
    color: $black-color-2;
  }

  .MuiInputBase-input.focus,
  .MuiInputBase-input:focus {
    box-shadow: 0px 0px 0px 2px $bg-blue-focus !important;
    border-color: $bg-gray-color-3 !important;
  }

  .MuiInputBase-input.active,
  .MuiInputBase-input:active {
    color: $primary-color !important;
  }

  .Mui-error .MuiInputBase-input {
    border-color: $bg-icon-error !important;
  }

  .MuiInputAdornment-root {
    position: absolute;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .MuiInputBase-root {
    position: relative;
    z-index: 0;
    padding: 0;
  }

  .MuiInputBase-input.MuiInputBase-inputAdornedStart {
    padding-left: 1rem;
  }

  .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
    padding-right: 4rem;
  }

  .MuiInputAdornment-positionEnd {
    right: 1rem;
  }

  .MuiInputAdornment-positionStart {
    left: 1rem;
    z-index: 1;
  }

  fieldset {
    display: none;
  }

  .MuiInputAdornment-root svg {
    font-size: $font-size-large;
    color: $black-color;
  }

  .MuiInputAdornment-root.QboTextField__password-visible-icon svg {
    color: $primary-color;
    cursor: pointer;
  }
}

.MuiPickersDay-dayWithMargin, .css-1e6y48t-MuiButtonBase-root-MuiButton-root,
.css-dplwbx-MuiPickersCalendarHeader-label, .css-1hbyad5-MuiTypography-root,
.css-innj4t-MuiPickersYear-yearButton{
  font-size: 12px !important;
}

.css-1vooibu-MuiSvgIcon-root{
  color: black !important;
}

.MuiStack-root{
  width: 100%;
}

// text field styles
.FormControl__default-wrapper {
  .QboTextField__label {
    color: $black-color;
    margin-bottom: 0.3rem;
    font-weight: $font-weight-semi-bold;
  }

  .QboTextField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .QboTextField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .QboTextField__error-text {
    font-size: $font-size-small;
    color: $bg-icon-error;
    margin-top: 0.6rem;
  }

  .QboTextField__warning-text {
    display: flex;
    font-size: 1.2rem;
    color: #f97d09;
    // margin-top: 0.2rem;
  }

  .QboTextField__warning-icon {
    padding-right: 3px;
    margin-right: 2px;
    padding-bottom: 2px;
  }

  .QboTextField__helper-text svg,
  .QboTextField__error-text svg {
    font-size: 1.3rem;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }
}

// select field styles
.FormControl__default-wrapper {
  .QboSelectField__label {
    color: $black-color;
    margin-bottom: 0.3rem;
    font-size: $font-size-default;
  }

  .QboSelectField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .QboSelectField__helper-text {
    color: $mute-color-3;
    font-size: $font-size-small;
  }

  .QboSelectField__error-text {
    font-size: $font-size-small;
    color: $bg-icon-error;
  }

  .QboSelectField__helper-text svg,
  .QboSelectField__error-text svg {
    font-size: 1.3rem;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }

  .QboSelectField__select .MuiInputBase-input {
    width: 100%;
  }

  .QboSelectField__select .MuiSelect-nativeInput {
    display: none;
  }

  .QboSelectField__select .MuiInputAdornment-positionEnd {
    right: 2.5em;
  }

  .QboSelectField__select .MuiSelect-icon {
    font-size: $font-size-large;
    color: $primary-color;
  }

  .QboSelectField__select .MuiInputBase-input.MuiInputBase-inputAdornedEnd {
    padding-right: 5.5rem;
  }

  .QboSelectField__select .MuiSelect-select {
    line-height: 1.9rem;
  }

  .QboSelectField__select .MuiInputAdornment-root svg {
    font-size: $font-size-large;
  }
}

.FormControl__default-wrapper.FormControl__horizontal-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  & > .MuiInputBase-root {
    min-width: 27%;
  }
}

.FormControl__default-wrapper.FormControl__full-width-wrapper {
  width: 100%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.FormControl__default-wrapper.FormControl__width-wrapper {
  width: 50%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.FormControl__default-wrapper.FormControl__width-wrapper_30 {
  width: 30%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.FormControl__default-wrapper.FormControl__width-wrapper_40 {
  width: 40%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.FormControl__default-wrapper.FormControl__width-wrapper_40_with_margin {
  width: 40%;
  margin-left: 5%;

  .MuiInputBase-input {
    width: 100%;
  }
}

.FormControl__default-wrapper.FormControl__width_set-wrapper {
  width: 30% !important;

  .MuiInputBase-input {
    width: 100%;
  }
}

.css-11a8txn-MuiStack-root {
  width: 100%;
}

.QboFormGroup__default {
  gap: 2.6rem;
  display: flex;
  flex-direction: column;
}

.QboFormGroup__wrapper {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
