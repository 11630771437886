.QboCheckbox__wrapper {
  .MuiTypography-root {
    // font-weight: $font-weight-semi-bold !important;
    font-size: $font-size-small !important;
    color: #1D2431 !important;
    font-family: $font-family !important;
  }
  
  .MuiCheckbox-root {
    svg {
      font-size: $font-size-large;
      color: $mute-color-2;
    }
  }

  .Mui-checked svg {
    color: $blue-color;
  }
}

.QboCheckbox__without-label .MuiFormControlLabel-root {
  margin: 0;
}
