@import '../variables';

.QboTipsBox__wrapper {
  padding: 1.2rem 2rem;
  border-radius: 0.4rem;
  font-size: $font-size-default;
  font-family: $font-family;
  margin-top: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.002rem;
  position: relative;
  overflow: hidden;

  p {
    margin: 0;
  }
}

.QboTipsBox__wrapper.QboTipsBox__primary-variant {
  background-color: $bg-primary;
  border: $thin-border;
  border-color: $border-primary;
}

.QboTipsBox__wrapper.QboTipsBox__info-variant {
  background-color: $bg-gray-color-1;
  border: $thin-border;
  border-color: $border-info-2;
}

.QboTipsBox__wrapper.QboTipsBox__warning-variant {
  background-color: $bg-warning;
  border: $thin-border;
  border-color: $border-warning;
}

.QboTipsBox__wrapper .QboTipsBox__corner-icon svg {
  position: absolute;
  right: -0.8rem;
  bottom: -0.8rem;
  transform: rotate(-30deg);
  color: $mute-color-2;
  font-size: 4rem;
}

.QboTipsBox__wrapper .MuiTypography-root.QboTipsBox__action-button {
  display: flex;
  align-items: center;
  color: $blue-color;
  font-size: $font-size-default;
  font-weight: $font-weight-bold;
  gap: 0.4rem;

  svg {
    font-size: $font-size-small;
  }
}
