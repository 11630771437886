@import '../variables';

.QboAlert__wrapper {
  &.MuiAlert-standardError {
    background: $bg-danger;
    border: 0.1rem solid $border-danger;
    border-radius: 0.4rem;
  }

  &.MuiAlert-standardSuccess {
    background: $bg-success-2;
    border: 0.1rem solid $border-success-2;
    border-radius: 0.4rem;
  }

  &.MuiAlert-standardInfo {
    background: $bg-success-2;
    border: 0.1rem solid $border-success-2;
    border-radius: 0.4rem;
  }

  p.MuiTypography-root,
  p {
    color: $black-color;
    font-size: $font-size-small;
    font-weight: $font-weight-default;
    color: $primary-color;
    margin: 0;

    b {
      font-weight: $font-weight-semi-bold;
    }
  }

  .MuiAlert-icon {
    padding: 0.8rem 0 0.8rem;
  }

  .MuiAlertTitle-root {
    font-size: $font-size-default;
    font-weight: $font-weight-semi-bold;
    color: $black-color;
  }

  .MuiAlert-action {
    padding: 0.8rem 0 0.8rem 8rem;
    svg {
      font-size: $font-size-default;
    }
  }
}

.QboAlert__wrapper.QboAlert__without-heading {
  .MuiAlert-icon {
    padding: 0.8rem 0 0.8rem;
  }

  .MuiAlert-icon p {
    display: flex;
  }

  p.MuiTypography-root,
  p {
    font-size: $font-size-default;
  }

  p.MuiTypography-root a,
  p a {
    color: $black-color;
    text-decoration: underline;
  }

  .MuiAlert-action {
    padding: 0.8rem 0 0.8rem 8rem;
  }
}
