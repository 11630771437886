@import '../variables';

.StepperBlock__wrapper {
  padding: 1.2rem !important;
  width: 100%;

  .MuiStepLabel-root .MuiStepIcon-text {
    font-family: $font-family !important;
    font-size: $font-size-default !important;
  }
  .MuiStepLabel-label {
    font-family: $font-family !important;
    font-size: $font-size-default !important;
  }
}

.StepperBlock__content {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.StepperBlock__footer {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
}
