@import '../variables';

.QboModal__default {
  font-family: $font-family;
  font-style: normal;
  font-weight: $font-weight-default;
  font-size: $font-size-small;
  line-height: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  outline: 0;

  .view_excluded_mailbox_modal{
    width: 80% !important;
  }
  .QboModal__wrapper {
    width: 80%;
    background-color: $bg-white-color;
    border: none;
    box-shadow: $shadow-depth-100;
    border-radius: 0.4rem;
    margin: 7rem auto;
    color: $black-color;
    overflow: hidden;
    outline: 0;
  }

  .Qbo_model_error_message{
    margin-top: 10%;
    width: 50%;
  }

  .QboModal__wrapper_interceptors {
    display: flex;
    position: relative;
    height: 30%;
    top: 35% !important;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .QboModal__wrapper.mail_modal {
    width: 80%;
  }
  .DataIngestion_modal{
    width: 80%;
    margin: 2rem auto
  }

  .DataIngestionJob_modal{
    width: 40%;
    margin: 10rem auto
  }

  .DataIngestionJobForm_modal{
    width: 40%;
    margin: 17rem auto
  }

  .DataIngestion_reports_modal{
    width: 70%;
    margin: 2rem auto;
  }

  .DataIngestion_reports_modal_min_data{
    width: 70%;
    margin: 20rem auto;
  }

  .DataIngestion_reports_modal_empty_data{
    width: 70%;
    margin: 9rem auto
  }
  .DataIngestion_reports_modal_eml_min_data{
    width: 60%;
    margin: 9rem auto
  }

  .DataIngestion_reports_modal_eml_data{
    width: 60%;
    margin: 2rem auto
  }
  
  .session_modal {
    position: relative;
    top:20%;
    width:30%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Qbo_module_style_on_click{
    width: 50%;
    margin-top: 10%;
  }

  .Qbo_module_style_on_click_with_no_records{
    width: 50%;
    margin-top: 20%;
  }

  .QboModal__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 1.2rem 2rem;
    box-shadow: $inset-shadow;
  }

  .QboModal__header .MuiTypography-root.MuiTypography-h5 {
    font-weight: $font-weight-bold;
    line-height: 3.2rem;
    color: $black-color;
  }

  .QboModal__close-icon {
    display: flex;
    align-items: center;

    svg {
      font-size: $font-size-extra-large;
    }
  }

  .QboModal__body {
    padding: 2rem;
    overflow-x: hidden;
    max-height: 68rem;
  }

  .QboModal__footer {
    .MuiLoadingButton-root {
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2.8rem 2rem;
    padding-top: 0;
    gap: 1rem;
  }

  .QboModal__button-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .QboModal__button-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .MuiLoadingButton-root {
      width: auto;
    }
  }

  .QboModal__button-no-padding button {
    padding: 0;
    height: 0;
    min-width: 0;
  }

  .Modal__card-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.2rem 2rem;
    gap: 0.8rem;

    background: $bg-white-color;

    border: 0.01rem solid $border-form-color;
    border-radius: 0.4rem;

    .Modal__list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.6rem;

      svg {
        font-size: 2.4rem;
        color: $primary-color;
      }
    }
  }
}
