@import '../variables';

.QboTabs__default {
  .MuiTabs-root .MuiButtonBase-root {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-default;
    color: $primary-color;
    text-transform: capitalize;

    &.Mui-selected {
      color: $blue-color;
    }

    svg {
      font-size: $font-size-icon-default;
    }
  }
}
