@import 'variables';

body {
  background-color: $bg-gray-color-2 !important;
}

.IconButton__default {
  &:hover {
    background: none !important;
  }
}

.Img__fluid {
  max-width: 100%;
  height: auto;
}

.InitialBackupSection__wrapper {
  button {
    margin-top: 1rem;
  }
}

.HeaderPageBlock__wrapper {
  margin-bottom: 2rem;

  a.MuiTypography-noWrap {
    line-height: 1.5rem;
  }

  .HeaderPageBlock__navigate {
    display: inline-block;
    font-size: $font-size-small !important;
    cursor: pointer;

    svg {
      font-size: $font-size-small !important;
      margin-right: 0.4rem;
    }

    span,
    svg {
      vertical-align: middle;
    }
  }

  .HeaderPageBlock__heading {
    color: $black-color !important;
  }
}

.css-mvoatl-MuiPaper-root-MuiAlert-root{
  background-color: #d32f2f !important;
  color: white !important;
  font-size: 14px !important;
  width: 100% !important;
}

.css-1vooibu-MuiSvgIcon-root{
  // margin-top: 2px !important;
  color: white !important;
}

.css-ki1hdl-MuiAlert-action{
  padding: 2px 0px 0px 10px !important;
}

.Display_hidden {
  display: none;
}

.Page__with-bottom-stepper {
  margin-bottom: 10rem;
}

.SearchBlock__wrapper {
  padding: 2rem 2.8rem;

  .FormControl__default-wrapper,
  .FormControl__default-wrapper .MuiInputBase-input {
    width: 100%;
    max-width: 36.5rem;
  }

  .FormControl__default-wrapper .SearchBlock__cancel-icon svg {
    color: $mute-color-2;
    visibility: hidden;
  }

  .FormControl__default-wrapper .SearchBlock__cancel-icon {
    cursor: default;
    display: flex;
  }

  .SearchBlock__text-field
    input:not([value='']):focus
    + .MuiInputAdornment-root
    .SearchBlock__cancel-icon
    svg {
    visibility: visible;
  }

  .SearchBlock__text-field
    input:not([value='']):focus
    + .MuiInputAdornment-root
    .SearchBlock__cancel-icon {
    cursor: pointer;
  }
}

.PaginationCardblock__wrapper {
  border-top: $thin-border;
  padding: 1.4rem 2.8rem;
}

.QboLogo__wrapper {
  display: flex;
  align-items: center;

  .QboLogo__main {
    max-width: 16.5rem;
    max-height: 3rem;
  }
}

.MuiDrawer-root .MuiBackdrop-root {
  background-color: rgb(0 0 0 / 23%);
}

.MuiPaper-root.DefaultApplication__navbar {
  padding-left: 0;
}

.DefaultApplication__not-ready {
  position: relative;

  .DefaultApplication__navigation {
    display: none;
  }

  .DefaultApplication__not-ready-loading {
    position: fixed;
    background-color: $bg-gray-color-2;
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .Box__main-with-padding {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

@media screen and (max-width: 600px) {
  .QboButton__default.MuiButton-sizeMedium {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.LoginPage__wrapper {
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;

  .LoginPage__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.CardItem_wrapper {
  position: relative;
  @media (min-width: 900px)  {
    height: calc(100% - 1vw);
  }
}

.MuiToolbar-root{
  height: 52px;
}

.MuiTablePagination-selectLabel {
  font-size: 13px !important;
  margin-bottom: 2px !important;
}

.MuiTablePagination-displayedRows {
  font-size: 13px !important;
  margin-bottom: 2px !important;
}

.MuiTablePagination-actions {
  font-size: 13px !important;
  margin-bottom: 4px !important;
}

.MainAlert__Wrapper {
  font-size: 20px !important;
  position: fixed;
  z-index: 99999;
  top: 30px;
  right: 30px;
  max-width: 290px;
  width: 330px;

  .MuiSvgIcon-root {
    height: 24px !important;
    width: 24px !important;
  }

  .MuiAlert-message,
  .MuiTypography-root {
    font-size: 14px;
  }
}

#hideMe {
  -moz-animation: cssAnimation 0s ease-in 7s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 7s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 7s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 7s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width:0;
    height:0;
    visibility:hidden;
    display: none;
  }
}