@import '../variables';

.Navbar__default {
  box-shadow: none !important;
  background-color: $bg-white-color !important;
  border-bottom: $thin-border;
}

.Link__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.Img-flag {
  width: 2.1rem;
}

@media (min-width: 600px) {
  .Navbar__default .MuiToolbar-root {
    min-height: 5.5rem;
    gap: 3rem;
  }
}
