.ResellersPage {
    &__match-text {
        padding: 1rem 2rem !important;
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #42526e;
    }

    &__settings-menu-bar {
        display: flex;
        justify-content: space-between;
    }

    &__settings-menu-section {
        padding: 1rem 2rem;
        flex-grow: 1;
        display: flex;
        gap: .8rem;
        align-items: center;
        
        &--start {
            justify-content: flex-start;
        }
        
        &--end {
            justify-content: flex-end;
        }
    }
    
    &__settings-search {
        width: 60% !important;
    }
    
    &__settings-toolbar {
        padding: 1rem 2rem;
        display: flex;
        justify-content: flex-end;
        gap: .8rem;
    }

    &__settings-card {
        margin-left: 2rem;
        width: calc(100% - 2rem);
    }

    &__cancel-button {
        &, &:active, &:focus {
            background-color: $mute-color-2 !important;
            color: $black-color !important;
            text-transform: unset !important;

            font-size: 1.4rem !important;
            height: 3.6rem !important;
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
    }
}