/**
* Default Value
**/

// Background
$bg-white-color: #fff !default;
$bg-gray-color: #e6eaf0 !default;
$bg-gray-color-1: #f7f9fc !default;
$bg-gray-color-2: #f2f4f6 !default;
$bg-gray-color-3: #d7dde4 !default;
$bg-gray-color-4: #e6e8ea !default;
$bg-gray-color-5: #d7dbe3 !default;
$bg-success: #e3fce9 !default;
$bg-success-disabled: rgba(16, 128, 0, 0.5) !default;
$bg-error: #fce3e6 !default;
$bg-error-2: #d51c25 !default;
$bg-icon-error: #dd3c51 !default;
$bg-icon-success: #25a742 !default;
$bg-icon-success-2: #0f7469 !default;
$bg-blue-color: #1c63d5 !default;
$bg-blue-color-2: #3678e3 !default;
$bg-blue-color-3: #002855 !default;
$bg-blue-focus: #1289f8 !default;
$bg-cyan-color: #e6effc !default;
$bg-warning: #faf5e1 !default;
$bg-danger: #fce3e4 !default;
$bg-primary: #e3edfc !default;
$bg-success-2: #e1faf7 !default;

// Font
$primary-color: #42526e !default;
$primary-color-2: #002f7b !default;
$black-color: #1d2431 !default;
$black-color-2: #5f6f89 !default;
$black-color-3: #1c1b1f !default;
$gray-color: #2d3748 !default;
$mute-color: #77808f !default;
$mute-color-2: #9ea5b0 !default;
$mute-color-3: #97a3b8 !default;
$active-color: $bg-blue-color !default;
$white-color: #fff !default;
$blue-color: #1c63d5 !default;
$warning-color: #5e4d00 !default;
$warning-color-2: #887212 !default;
$danger-color: #7b0006 !default;
$danger-color-2: #c30a13 !default;
$danger-color-3: #d51c25 !default;
$danger-color-4: $bg-error-2 !default;
$danger-color-5: #d51c25 !default;
$success-color: #004d44 !default;
$tosca-color: #0f7469 !default;

// border
$border-error: #f4a9b3 !default;
$border-error-2: $bg-error-2 !default;
$border-success: #a8dcb4 !default;
$border-success-2: #9cd9d3 !default;
$border-info: #9ea5b0 !default;
$border-info-2: #f0f2f6 !default;
$border-color: $bg-gray-color !default;
$border-form-color: $bg-gray-color-5 !default;
$thin-border: 1px solid $border-color !default;

$border-warning: #ded3a0 !default;
$border-danger: #f9b4b8 !default;
$border-primary: #b4cff9 !default;

/**
* Typography
**/
$font-family: 'Inter' !default;
$font-line-height-default: 24px !default;
$font-letter-spacing-default: 0.02px !default;
$font-color-default: $primary-color !default;

$font-size-banner: 12rem !default;
$font-size-extra-large: 2.2rem !default;
$font-size-large: 1.8rem !default;
$font-size-default: 1.4rem !default;
$font-size-small: 1.2rem !default;
$font-size-extra-small: 1rem !default;
$font-size-icon-default: 2rem !default;
$font-size-icon-small: 1.5rem !default;

$font-weight-default: 400 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-extra-bold: 700 !default;

$font-size-h6: 1.25rem !default;
$font-size-h5: 1.8rem !default;
$font-size-h4: 2.125rem !default;
$font-size-h3: 3rem !default;
$font-size-h2: 3.75rem !default;
$font-size-h1: 6rem !default;

$font-size-heading-list: (
  h6: $font-size-h6,
  h5: $font-size-h5,
  h4: $font-size-h4,
  h3: $font-size-h3,
  h2: $font-size-h2,
  h1: $font-size-h1,
);

/**
* Wrapepr
**/
$shadow-depth-10: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !default;
$shadow-depth-100: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 7px 20px rgba(9, 14, 18, 0.1) !default;
$shadow-depth-1000: 0px 5px 20px rgba(0, 0, 0, 0.05), 0px 15px 30px rgba(0, 0, 0, 0.15) !default;
$inset-shadow: inset 0rem -0.1rem 0rem $bg-gray-color-5;

$shadow-list: (
  depth-10: $shadow-depth-10,
  depth-100: $shadow-depth-100,
  depth-1000: $shadow-depth-1000,
);

// drawer height
$drawer-height-breadcrumbs: 4.4rem;
$drawer-height-heading: 5.6rem;
$drawer-height-bottom-bar: 6.4rem;

// others
$transition-default: all 0.2s ease !default;
