@import '../variables';

.QboDropdown__wrapper {

  .MuiButtonBase-root,
  .MuiButtonBase-root svg {
    font-size: $font-size-default;
    color: $primary-color;
    font-family: $font-family;
  }
}

.QboDropdown__menu {
  .MuiMenu-list {
    min-width: 16rem;
  }

  .MuiMenuItem-root {
    font-size: $font-size-default;
    color: $primary-color;
  }

  .MuiMenuItem-divider {
    border-bottom: $thin-border;
  }
}

.QboMenuItem__with-danger-color {
  .MuiTypography-root {
    color: $primary-color ;
  }
}

.QboDropdown__button {
  .MuiButtonBase-root {
    flex-direction: row;
    border: 1px solid $bg-blue-color;
    border-radius: 4px;
    color: $active-color;
    height: 3.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .MuiButtonBase-root svg {
    font-size: $font-size-large;
    color: $bg-blue-color;
  }

  .MuiTypography-root {
    font-weight: $font-weight-semi-bold;
  }
}

.QboDropdown__button-filter {
  .MuiButtonBase-root {
    flex-direction: row;
    border: 1px solid $border-form-color;
    border-radius: 4px;
    color: $black-color;
    height: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 2rem;
  }

  .MuiButtonBase-root svg {
    font-size: $font-size-large;
    color: $black-color;
  }

  .MuiTypography-root {
    font-weight: $font-weight-semi-bold;
  }
}

.QboMenuItem__sort-variant {
  .MuiTypography-root {
    color: $primary-color ;
  }

  .MuiMenuItem-root:first-child .MuiTypography-root {
    font-size: $font-size-extra-small;
  }
}

.QboDropdown__account-menu {
  .MuiPaper-root {
    width: 22rem;
  }
}
