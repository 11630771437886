@import '../variables';

.SystemStatusSection__wrapper {
  display: flex;

  .SystemStatusSection__create-button {
    padding: 1.5rem 2rem 2rem;
    justify-content: flex-end;
  }
  .QboCardListItem__left-content {
    align-items: center;
    svg {
      font-size: 3.5rem;
      color: $blue-color;
    }
  }
  .SystemStatusSection__card-action {
    padding: 1em 1rem 1rem;
    justify-content: flex-end;
  }
}
