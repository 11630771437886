@import '../variables';

.FormPage__wrapper {
  display: flex;
  min-height: 60vh;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;

  .QboFormGroup__wrapper {
    min-width: 30vw;
  }

  // form div:first-child {
  //   min-width: 30vw;
  // }
}
