@import '../variables';

.ConfirmationBlock__wrapper {
  .ConfirmationBlock__content {
    justify-content: center;
    align-items: center;
    min-height: 15vh;
  }

  .ConfirmationBlock__button {
    justify-content: space-between;
  }
}
